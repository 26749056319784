$(document).ready(function() {
    var counters1 =document.getElementById("counters1");


    $(window).on('scroll', function () {
        // if(isInViewport(counters1)){
        //     // console.log('ffff');
        //     $('.temp0').hide();
        //     $('.counterincreaser').each(function () {
        //         $(this).prop('Counter',0).animate({
        //             Counter: $(this).text()
        //             }, {
        //                 duration: 2000,
        //                 easing: 'swing',
        //                 step: function (now) {
        //                     $(this).text(Math.ceil(now));
        //                 }
        //             });
        //         });
        //     $('.counterincreaser').each(function () {
        //         $("span").removeClass("counterincreaser");
        //     });
        // };
    });

    var swiper = new Swiper(".mySwiper1", {
        rewind: true,
        slidesPerView: 3,
        spaceBetween: 0,
        loop: true,
        // centeredSlides: true,
        // freeMode: true,
        autoplay: {
        delay: 2500,
        disableOnInteraction: false
        },
        breakpoints: {
            320: {
            slidesPerView: 1,
            spaceBetween: 0
            },
            576: {
            slidesPerView: 2,
            spaceBetween: 0
            },
            640: {
            slidesPerView: 2,
            spaceBetween: 0
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 0
            },
            1200:{
                slidesPerView: 4,
                spaceBetween: 0
            }
        },
        // scrollbar: {
        //   el: ".swiper-scrollbar100",
        //   hide: false
        // },
        navigation: {
        nextEl: ".swiper-button-next1",
        prevEl: ".swiper-button-prev1"
        }
    });
});  
// function isInViewport(element) {
//     const rect = element.getBoundingClientRect();
//     return (
//         rect.top >= 0 &&
//         rect.left >= 0 &&
//         rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
//         rect.right <= (window.innerWidth || document.documentElement.clientWidth)
//     );
// }





$(document).ready(function() {
    $('#carousel-example2').on('slide.bs.carousel', function (e) {

        var $e = $(e.relatedTarget);
        var idx = $e.index();
        var itemsPerSlide = 5;
        var totalItems = $('#carousel-example2 .carousel-item').length;
        
        if (idx >= totalItems-(itemsPerSlide-1)) {
            var it = itemsPerSlide - (totalItems - idx);
            for (var i=0; i<it; i++) {
                // append slides to end
                if (e.direction=="left") {
                    $('#carousel-example2 .carousel-item').eq(i).appendTo('#carousel-example2 .carousel-inner');
                }
                else {
                    $('#carousel-example2 .carousel-item').eq(0).appendTo('#carousel-example2 .carousel-inner');
                }
            }
        }
    });
    
});
